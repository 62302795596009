import React from "react"
import Layout from "../shared/layout/layout"
import Seo from "../shared/seo/seo"
import ServiceImg from "../components/services/service-img"
import "../styles/services.scss"
import { graphql, useStaticQuery } from "gatsby"

const Services = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          services {
            title
            items_services {
              label
            }
            paragraphs {
              label
            }
            img_description
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title={data.site.siteMetadata.services.title}></Seo>
      <div className="no-gutters row">
        <div className=" col-xl-6 col-lg-6 col-md-12">
          <div className="row no-gutters">
            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-12">
              <div className="main-img">
                <ServiceImg></ServiceImg>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12">
              <div className="title-img">
                {data.site.siteMetadata.services.img_description}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="paragraphs">
            {data.site.siteMetadata.services.paragraphs.map((items, index) => {
              return (
                <p className="paragraph-service" key={index}>
                  {items.label}
                </p>
              )
            })}
            <ul>
              {data.site.siteMetadata.services.items_services.map(
                (values, index) => {
                  return (
                    <li className="list-item mt-4" key={index}>
                      {values.label}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Services
